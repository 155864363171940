import React from 'react';
import topLeft from '../../Assets/top-left.png';
import bottomLeft from '../../Assets/bottom-left.png';
import topright from '../../Assets/top-right.png';
import bottomright from '../../Assets/bottom-right.png';

import charm from '../../Assets/charms.png';
import digital from '../../Assets/digital.png';
import location from '../../Assets/location.png';


import '../AboutUs/AboutUs.css';

function AboutUs() {
  return (
    <div className='aboutUs-container' id='aboutUs'>
        <div className='row-about-header'>
            <div className='col-about-child'>
                <div>
                    <img src={topLeft} className='line'></img>
                </div>
                <div>
                    <img src={bottomLeft} className='line'></img>
                </div>
            </div>
            <div className='col-about-child '>
                <h2>
                    ABOUT US
                </h2>
            </div>
            <div className='col-about-child '>
                <div>
                    <img src={topright}></img>
                </div>
                <div>
                    <img src={bottomright}></img>
                </div>
            </div>
        </div>

        <div className='row-about'>
            <div className='col-about'>
                <div className='col-about-child'>
                    <img src={charm} className='photoAbout'/>
                </div>
                <div className='col-about-child'>
                    <h3>FASCINATING CHARM</h3>
                </div>
                <div>
                    <p className='aboutParagraph' align="justify">
                        With its elegant oriental charms, Its is found in the heart of Ormoc City, hailed as the Philippine's safest city, and the gateway to Western Leyte.
                    </p>
                </div>
            </div>
            <div className='col-about'>
                <div className='col-about-child'>
                    <img src={digital} className='photoAbout'/>
                </div>
                <div className='col-about-child'>
                    <h3>DIGITALLY READY</h3>
                </div>
                <div>
                    <p className='aboutParagraph' align="justify">
                        Equipped with on-site Internet distribution, make the spaces ideal for ICT operations or retail POS systems

                    </p>
                </div>
            </div>
            <div className='col-about'>
                <div className='col-about-child'>
                    <img src={location} className='photoAbout'/>
                </div>
                <div className='col-about-child'>
                    <h3>STRATEGIC LOCATION</h3>
                </div>
                <div>
                    <p className='aboutParagraph' align="justify">
                        A 5-minute drive from Downtown Ormoc, we are found in the national highway to Tacloban City at the north Maasin City at the south.
                    </p>
                </div>
            </div>
        </div>



    </div>
   
    
  );
}

export default AboutUs;
